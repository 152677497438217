import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Pair, Token } from 'sdk';
import { Link } from 'react-router-dom';
import { SwapPoolTabs } from '../../components/NavigationTabs';
import AppBody from '../AppBody';
import FullPositionCard from '../../components/PositionCard';
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks';
import { TYPE } from '../../theme';
import { RowFixed } from '../../components/Row';
import { ButtonPrimary } from '../../components/Button';
import { useDarkModeManager } from '../../state/user/hooks';
import { useActiveWeb3React } from '../../hooks';
import { usePairs } from '../../data/Reserves';
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks';
import { Dots } from '../../components/swap/styleds';
import { generatePairAddressAsync } from 'utils/pairAddressHelper';
import LeftPages from 'components/swap/LeftPages';
import WlltLogo from '../../assets/svg/wallet-add.svg';
import WlltLogoDark from '../../assets/svg/wallet-add.svg';
import { useWalletModalToggle } from '../../state/application/hooks';

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  flex-direction: column;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    // width: 100%;
    // flex-direction: row-reverse;
    // justify-content: space-between;
  `};
`;

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: 100%;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.primary1};
  background-color: rgb(0 198 255 / 10%);
  :hover {
    color: #fff;
  }
`;

type TokenPairsWithLiquidityTokens = {
  liquidityToken: Token;
  tokens: [Token, Token];
};

export default function Pool() {
  const theme = useContext(ThemeContext);
  const { account } = useActiveWeb3React();
  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs();
  // const tokenPairsWithLiquidityTokens = useMemo(() => {
  //   return trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens }));
  // }, [trackedTokenPairs]);

  useEffect(() => {
    console.log('trackedTokenPairs');
  }, [trackedTokenPairs]);

  const [tokenPairsWithLiquidityTokens, setTokenPairsWithLiquidityTokens] = useState<TokenPairsWithLiquidityTokens[]>(
    []
  );

  useEffect(() => {
    const status = { cancelled: false };
    (async () => {
      console.log('Came Here');
      const generatedLiquidityTokens = [];
      for (const tokens of trackedTokenPairs) {
        await generatePairAddressAsync(tokens[0], tokens[1]);
        generatedLiquidityTokens.push({
          liquidityToken: toV2LiquidityToken(tokens),
          tokens,
        });
      }
      if (!status.cancelled) {
        setTokenPairsWithLiquidityTokens(generatedLiquidityTokens);
      }
    })();
    return () => {
      status.cancelled = true;
    };
  }, []);

  useEffect(() => {
    console.log('tokenPairsWithLiquidityTokens');
  }, [tokenPairsWithLiquidityTokens]);

  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  );
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  );

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  );

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens));
  const v2IsLoading =
    fetchingV2PairBalances ||
    v2Pairs?.length < liquidityTokensWithBalances.length ||
    v2Pairs?.some((V2Pair) => !V2Pair);
  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair));
  const [darkMode] = useDarkModeManager();
  const toggleWalletModal = useWalletModalToggle();
  return (
    <>
      <div className="swap_inner">
        <LeftPages />
        <SwapPoolTabs active={'pool'} />
        <AppBody>
          <div className="pl_bdy">
            <h6>Your liquidity</h6>
            <ButtonRow>
              <ResponsiveButtonPrimary as={Link} to="/find">
                Pool Finder
              </ResponsiveButtonPrimary>
              <ResponsiveButtonPrimary id="join-pool-button" as={Link} to="/add/ETH">
                Add Liquidity
              </ResponsiveButtonPrimary>
            </ButtonRow>
            {!account ? (
              <div className="cardAs">
                <img width={'140px'} src={darkMode ? WlltLogo : WlltLogoDark} alt="logo" />
                <TYPE.body color={theme.text3} textAlign="center">
                  Connect to a wallet to view your liquidity.
                </TYPE.body>
              </div>
            ) : v2IsLoading ? (
              <div className="cardAs">
                <TYPE.body color={theme.text3} textAlign="center">
                  <Dots>Loading</Dots>
                </TYPE.body>
              </div>
            ) : allV2PairsWithLiquidity?.length > 0 ? (
              <>
                {allV2PairsWithLiquidity.map((v2Pair) => (
                  <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                ))}
              </>
            ) : (
              <div className="cardAs">
                <TYPE.body color={theme.text3} textAlign="center">
                  No Liquidity found
                </TYPE.body>
              </div>
            )}
          </div>
          {!account && (
            <div className="amount_btn">
              <ButtonPrimary onClick={toggleWalletModal} style={{ background: theme.primary1 }} color={theme.text7}>
                Connect Wallet
              </ButtonPrimary>
            </div>
          )}
        </AppBody>
      </div>
    </>
  );
}
