import { Currency, ETHER, Token } from 'sdk';
import React, { useMemo } from 'react';
import styled from 'styled-components';

// import EthereumLogo from '../../assets/images/doge-logo.png';

import ETH from '../../assets/images/token-list/eth.svg';
import WETH from '../../assets/images/token-list/weth.png';
import ZKDEFI from '../../assets/images/token-list/zkdefi.png';
import DOGEPOW from '../../assets/images/token-list/dogepow.jpg';
import POWGE from '../../assets/images/token-list/powge.png';
import USDT from '../../assets/images/token-list/usdt.png';
import USDC from '../../assets/images/token-list/usdc.png';
import ETHWINU from '../../assets/images/token-list/ethwinu.png';
import OKDEX from '../../assets/images/token-list/okdex.png';
import POWDLE from '../../assets/images/token-list/powdle.jpg';
import WBTC from '../../assets/images/token-list/wbtc.png';
import OHMW from '../../assets/images/token-list/ohmw.png';
import PPR from '../../assets/images/token-list/paper.png';
import SHIWA from '../../assets/images/token-list/shiwa.png';
import SHIBAW from '../../assets/images/token-list/shibaw.jpg';
import SLR from '../../assets/images/token-list/SLR.png';
import POWV from '../../assets/images/token-list/POWV.png';
import POWP from '../../assets/images/token-list/POWP.png';
import PP from '../../assets/images/token-list/POWPAD.png';

import useHttpLocations from '../../hooks/useHttpLocations';
import { WrappedTokenInfo } from '../../state/lists/hooks';
import Logo from '../Logo';

const getTokenLogoURL = (address: string) =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`;

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`;

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
`;

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency;
  size?: string;
  style?: React.CSSProperties;
}) {
  const url = currency instanceof WrappedTokenInfo ? currency.logoURI : '';
  const url1 = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined);
  const uriLocations = url?.startsWith('images') ? url : url1;

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return [];

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, url?.startsWith('images') ? url : getTokenLogoURL(currency.address)];
      }

      return [getTokenLogoURL(currency.address)];
    }
    return [];
  }, [currency, uriLocations, url]);

  if (currency === ETHER) {
    return <StyledEthereumLogo src={ETH} size={size} style={style} />;
  }

  if (currency?.name === 'WETH') {
    return <StyledEthereumLogo src={WETH} size={size} style={style} />;
  }

  if (currency?.name === 'ZKUNI') {
    return <StyledEthereumLogo src={ZKDEFI} size={size} style={style} />;
  }

  if (currency?.name === 'USDC') {
    return <StyledEthereumLogo src={USDC} size={size} style={style} />;
  }

  if (currency?.name === 'USDT') {
    return <StyledEthereumLogo src={USDT} size={size} style={style} />;
  }

  if (currency?.name === 'Ether') {
    return <StyledEthereumLogo src={ETH} size={size} style={style} />;
  }

  if (currency?.name === 'ETH') {
    return <StyledEthereumLogo src={ETH} size={size} style={style} />;
  }

  if (currency?.name === 'WBTC') {
    return <StyledEthereumLogo src={WBTC} size={size} style={style} />;
  }

  if (currency?.name === 'DOGEPOW') {
    return <StyledEthereumLogo src={DOGEPOW} size={size} style={style} />;
  }

  if (currency?.name === 'POWGE') {
    return <StyledEthereumLogo src={POWGE} size={size} style={style} />;
  }

  if (currency?.name === 'OHMW') {
    return <StyledEthereumLogo src={OHMW} size={size} style={style} />;
  }

  if (currency?.name === 'PPR') {
    return <StyledEthereumLogo src={PPR} size={size} style={style} />;
  }

  if (currency?.name === 'ETH INU') {
    return <StyledEthereumLogo src={ETHWINU} size={size} style={style} />;
  }

  if (currency?.name === 'OKDEX') {
    return <StyledEthereumLogo src={OKDEX} size={size} style={style} />;
  }

  if (currency?.name === 'POWDLE') {
    return <StyledEthereumLogo src={POWDLE} size={size} style={style} />;
  }

  if (currency?.name === 'SHIWA') {
    return <StyledEthereumLogo src={SHIWA} size={size} style={style} />;
  }

  if (currency?.name === 'ShibaW Inu') {
    return <StyledEthereumLogo src={SHIBAW} size={size} style={style} />;
  }

  if (currency?.name === 'SOLARIS') {
    return <StyledEthereumLogo src={SLR} size={size} style={style} />;
  }

  if (currency?.name === 'POWERVAULT') {
    return <StyledEthereumLogo src={POWV} size={size} style={style} />;
  }
  if (currency?.name === 'POWPAD') {
    return <StyledEthereumLogo src={PP} size={size} style={style} />;
  }

  if (currency?.name === 'Pirates') {
    return <StyledEthereumLogo src={POWP} size={size} style={style} />;
  }
  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />;
}
