import React from 'react';
import styled from 'styled-components';
import { useDarkModeManager } from '../../state/user/hooks';
import Logo from '../../assets/svg/zkDeFi_logo_lifth.svg';
import LogoDark from '../../assets/svg/zkDeFi_logo_dark.svg';

const AsFooter = styled.div`
  padding: 12px;
  margin-top: 4em;
`;

const CxPadBox = styled.div`
  background: ${({ theme }) => theme.bg1};
  border-radius: 15px;
  padding: 24px 30px 30px 30px;
  max-width: 400px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 24px 10px 30px 10px;
    `};
`;
const TopCntnt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 15px;
`;
const LeftBox = styled.div`
  display: flex;
  align-items: center;
`;
const RightBox = styled.div``;
const ButButton = styled.button`
  padding: 5px 24px;
  color: white;
  background-color: ${({ theme }) => theme.primary1};
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  border-radius: 4px;
`;
const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LeftSide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  margin-bottom: 8px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        width: 130px;
    `};
`;
const LeftP = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.text3};
  margin: 0;
`;
const LeftClmn = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.text3};
  margin: 0;
`;
const Righth4 = styled.h4`
  margin: 0;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.text1};
  text-align: right;
`;

export default function LeftPages() {
  const [darkMode] = useDarkModeManager();
  return (
    <>
      <AsFooter className="left_section">
        <CxPadBox className="left_section_zkdefi">
          <TopCntnt className="brd_box">
            <LeftBox>
              <img width={'55px'} height={'24px'} src={darkMode ? LogoDark : Logo} alt="logo" />
            </LeftBox>
            <RightBox>
              <ButButton className="buy_unw_btn">Buy ZKDEFI</ButButton>
            </RightBox>
          </TopCntnt>
          <BottomContent>
            <div>
              <LeftSide>
                <LeftP>Max supply</LeftP>
                <LeftClmn>:</LeftClmn>
              </LeftSide>
              <LeftSide>
                <LeftP>Circulating supply</LeftP>
                <LeftClmn>:</LeftClmn>
              </LeftSide>
              <LeftSide>
                <LeftP>Sell Tax</LeftP>
                <LeftClmn>:</LeftClmn>
              </LeftSide>
              <LeftSide>
                <LeftP>Total Burned</LeftP>
                <LeftClmn>:</LeftClmn>
              </LeftSide>
              <LeftSide>
                <LeftP>Market Cap</LeftP>
                <LeftClmn>:</LeftClmn>
              </LeftSide>
            </div>
            <div>
              <Righth4>50.000.000</Righth4>
              <Righth4>5.000.000</Righth4>
              <Righth4>3%</Righth4>
              <Righth4>0</Righth4>
              <Righth4>$500.000</Righth4>
            </div>
          </BottomContent>
        </CxPadBox>
      </AsFooter>
    </>
  );
}
