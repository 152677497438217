import { Currency, Percent, Price } from 'sdk';
import React from 'react';
import { ONE_BIPS } from '../../constants';
import { Field } from '../../state/mint/actions';
export function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price,
}: {
  currencies: { [field in Field]?: Currency };
  noLiquidity?: boolean;
  poolTokenPercentage?: Percent;
  price?: Price;
}) {
  return (
    <>
      <div className="clmGrid">
        <label>
          {currencies[Field.CURRENCY_B]?.symbol} per {currencies[Field.CURRENCY_A]?.symbol}
        </label>
        <h5>{price?.toSignificant(6) ?? '-'}</h5>
      </div>
      <div className="clmGrid">
        <label>
          {currencies[Field.CURRENCY_A]?.symbol} per {currencies[Field.CURRENCY_B]?.symbol}
        </label>
        <h5>{price?.invert()?.toSignificant(6) ?? '-'}</h5>
      </div>
      <div className="clmGrid">
        <label>Share of Pool</label>
        <h5>
          {noLiquidity && price
            ? '100'
            : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
          %
        </h5>
      </div>
    </>
  );
}
