import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { useTranslation } from 'react-i18next';
import { NavLink, Link as HistoryLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'state';
import { resetMintState } from 'state/mint/actions';
import Arrow from '../../assets/svg/backarrow.svg';
import ArrowDark from '../../assets/svg/darkbackarrow.svg';
import { useDarkModeManager } from '../../state/user/hooks';
import QuestionHelper from '../QuestionHelper';

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
`;

const activeClassName = 'ACTIVE';

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 20px;
  width: auto;
  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`;

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 20px;
`;
const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;

  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`;
const HoverText = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export function SwapPoolTabs({ active }: { active: 'swap' | 'pool' }) {
  const { t } = useTranslation();
  return (
    <Tabs style={{ marginBottom: '20px', display: 'none' }}>
      <StyledNavLink id={`swap-nav-link`} to={'/swap'} isActive={() => active === 'swap'}>
        {t('swap')}
      </StyledNavLink>
      <StyledNavLink id={`pool-nav-link`} to={'/pool'} isActive={() => active === 'pool'}>
        {t('pool')}
      </StyledNavLink>
    </Tabs>
  );
}

export function FindPoolTabs() {
  const [darkMode] = useDarkModeManager();
  return (
    <Tabs>
      <HeaderRow className="contct_border contct_borderv2">
        <HoverText className="back_file">
          <HistoryLink to="/pool">
            <img width={'16px'} height={'16px'} src={darkMode ? ArrowDark : Arrow} alt="logo" />
          </HistoryLink>
          Import Pool
          <QuestionHelper text="Tip: Use this tool to find pairs that don't automatically appear in the interface." />
        </HoverText>
      </HeaderRow>
    </Tabs>
  );
}

export function AddRemoveTabs({ adding, creating }: { adding: boolean; creating: boolean }) {
  // reset states on back
  const dispatch = useDispatch<AppDispatch>();
  const [darkMode] = useDarkModeManager();
  return (
    <Tabs>
      <HeaderRow className="contct_border contct_borderv2">
        <HoverText className="back_file">
          <HistoryLink
            to="/pool"
            onClick={() => {
              adding && dispatch(resetMintState());
            }}
          >
            <img width={'16px'} height={'16px'} src={darkMode ? ArrowDark : Arrow} alt="logo" />
          </HistoryLink>
          <ActiveText>{creating ? 'Create a pair' : adding ? 'Add Liquidity' : 'Remove Liquidity'}</ActiveText>
          <QuestionHelper text="Tip: When you add liquidity, you will receive pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time." />
        </HoverText>
      </HeaderRow>
    </Tabs>
  );
}
